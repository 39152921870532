export const isAdmin = () => {
    const userInfoStore = useUserInfoStore()
    if (userInfoStore.getUserInfo.hasOwnProperty('role'))  {
        let role = userInfoStore.getUserInfo.role.toLowerCase();
        if (['admin','supremo'].includes(role)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

